@tailwind base;
@tailwind components;
@tailwind utilities;

body {
}

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}
